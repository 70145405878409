"use client";

import classNames from "classnames";
import { useRouter } from "next/navigation";

import { Wrapper } from "@/components/Wrapper";
import { FooterCopyright } from "@/components/Footer/FooterCopyright";
import { Button } from "@/components/Button";
import { Link } from "@/components/Link";
import { Typography } from "@/components/Typography";

import { paths } from "@/utils/paths";
import { getSiteInfo } from "@/sites";

import { FooterProps } from "./types";

import { Logo } from "../Logo";
import { useContext } from "react";
import { AmplitudeContext } from "@/contexts/AmplitudeContext/AmplitudeContext";

import "./styles.scss";
import { SiteName } from "@/sites/types";

export const Footer = ({ footerCTAHref, noMb, customClass }: FooterProps) => {
  const { siteBlackLogoFooter, siteName } = getSiteInfo(process.env.NEXT_PUBLIC_DOMAIN!) || {};

  const router = useRouter();
  const { setSource } = useContext(AmplitudeContext);
  const classnames = classNames("footer", {
    footerNoMB: noMb,
    [`${customClass}`]: customClass,
  });

  const onCreateQrCodeClick = () => {
    setSource("link_footer_create");
    router.push(footerCTAHref);
  };

  const onPricesClick = () => {
    setSource("footer_plan_&_pricing");
    router.push(footerCTAHref);
  };

  return (
    <footer
      data-testid="footer"
      className={classnames}>
      <Wrapper
        customClass="footer__wrapper"
        xl>
        <div className="footer__section-left">
          <div className="footer__section logoSection">
            <Logo
              src={siteBlackLogoFooter}
              siteName={siteName}
              dataQa="footer-logo-link"
              dataTestId="footer-logo"
            />
          </div>
          <div
            className="footer__section cta-mobile"
            data-testid="footer-section">
            <div className="footer__section-cta-title">
              <Typography
                Tag="p"
                size="l">
                Make your own <br /> QR codes today.
              </Typography>

              <Button
                dataQA="footer-qr-editor-button"
                label="Create QR code"
                className="footer"
                large
                onClick={onCreateQrCodeClick}
                rounded={siteName === SiteName.QRNOW}
              />
            </div>
          </div>
        </div>
        <div className="footer__section-right">
          <div
            className="footer__section cta"
            data-testid="footer-section">
            <div className="footer__section-cta-title">
              <Typography
                Tag="p"
                size="l">
                Make your own <br /> QR codes today.
              </Typography>

              <Button
                dataQA="footer-qr-editor-button"
                label="Create QR code"
                className="footer"
                medium
                onClick={onCreateQrCodeClick}
                rounded={siteName === SiteName.QRNOW}
              />
            </div>
          </div>
          <div className="footer__section-column">
            <div className="footer__title">Company</div>
            <ul className="footer__links">
              <li>
                <Link
                  href={paths.whoWeAre()}
                  dataQA="footer-who-we-are-button">
                  Who we are
                </Link>
              </li>
              <li>
                <Link
                  href={paths.termsAndConditions()}
                  dataQA="footer-terms-and-conditions-button">
                  Terms and Conditions
                </Link>
              </li>
              <li>
                <Link
                  href={paths.termsOfUse()}
                  dataQA="footer-terms-of-use-button">
                  Terms of Use
                </Link>
              </li>
              <li>
                <Link
                  href={paths.privacyPolicy()}
                  dataQA="footer-privacy-policy-button">
                  Privacy policy
                </Link>
              </li>
              <li>
                <Link
                  href={paths.cookiesPolicy()}
                  dataQA="footer-cookies-policy-button">
                  Cookies policy
                </Link>
              </li>
              <li>
                <Link
                  href={paths.pricing()}
                  dataQA="footer-prices-button"
                  onClick={onPricesClick}>
                  Prices
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer__section-column">
            <div className="footer__title">Need help?</div>
            <ul className="footer__links">
              <li>
                <Link
                  href={paths.contactUs()}
                  dataQA="footer-contact-us-button">
                  Contact us
                </Link>
              </li>
              <li>
                <Link
                  href={paths.faq()}
                  dataQA="footer-faq-button">
                  FAQ
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Wrapper>
      <FooterCopyright />
    </footer>
  );
};
